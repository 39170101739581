<template>
  <div>
    <v-form
      ref="refCalendarEventHandlerForm"
      class=""
      @submit.prevent="handleFormSubmit"
    >
      <v-text-field
        v-model="eventLocal.title"
        label="Name"
        placeholder="Name"
        outlined
        :rules="[validators.required]"
        hide-details="auto"
        dense
        class="mb-6"
      ></v-text-field>

      <v-select
        v-model="eventLocal.type"
        outlined
        label="Calendar"
        placeholder="Calendar"
        :item-text="item => item.label"
        :item-value="item => item.value"
        :items="$store.state['app-calendar'].calendarOptions"
        :rules="[validators.required]"
        hide-details="auto"
        :menu-props="{ offsetY: true }"
        dense
        class="mb-6"
      >
        <!-- Selected Item Slot -->
        <template #selection="{ item }">
          <div class="d-flex align-center">
            <v-badge
              inline
              :color="item.color"
              dot
              class="me-2"
            ></v-badge>
            <span>{{ item.label }}</span>
          </div>
        </template>

        <!-- Options Slot -->
        <template #item="{ item }">
          <div class="d-flex align-center">
            <v-badge
              inline
              :color="item.color"
              dot
              class="me-2 mb-1"
            ></v-badge>
            <span>{{ item.label }}</span>
          </div>
        </template>
      </v-select>

      <v-menu
        v-model="isStartDateMenuOpen"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        eager
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="eventLocal.event_start_date"
            label="Start Date"
            readonly
            outlined
            dense
            class="mb-6"
            hide-details="auto"
            v-bind="attrs"
            :rules="[validators.required]"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="eventLocal.event_start_date"
          @input="isStartDateMenuOpen = false"
        ></v-date-picker>
      </v-menu>

      <v-menu
        v-model="isEndDateMenuOpen"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="eventLocal.event_end_date"
            label="End Date"
            readonly
            outlined
            dense
            class="mb-6"
            hide-details="auto"
            v-bind="attrs"
            :rules="[validators.required, validators.endDateValidator]"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="eventLocal.event_end_date"
          @input="isEndDateMenuOpen = false"
        ></v-date-picker>
      </v-menu>

      <v-textarea
        v-model="eventLocal.description"
        outlined
        label="Description"
        placeholder="Description"
      ></v-textarea>

      <div class="d-flex justify-center">
        <v-btn
          color="primary"
          class="mx-2"
          type="submit"
          large
          :loading="isLoadingButton"
          :disabled="!eventLocal.title || !eventLocal.type || !eventLocal.description || !eventLocal.event_start_date || !eventLocal.event_end_date"
        >
          {{ eventLocal.uuid ? 'Update' : 'Tambah ' }}
        </v-btn>
        <v-btn
          v-if="!isEdit"
          class="mx-2"
          outlined
          large
          color="primary"
          @click="resetEventLocal"
        >
          Reset
        </v-btn>
      </div>
    </v-form>
  </div>
</template>

<script>
import { onMounted, ref, watch } from '@vue/composition-api'
// import { PerfectScrollbar } from 'vue2-perfect-scrollbar'

import { dateInPast } from '@core/utils'
import { required, urlValidator } from '@core/utils/validation'
import { mdiClose, mdiTrashCanOutline } from '@mdi/js'

export default {
  components: {
    // PerfectScrollbar,
  },
  props: {
    event: {
      type: Object,
      required: true,
    },
    clearEventData: {
      type: Function,
      required: true,
    },
    isEdit: {
      type: Boolean,
      required: false,
    },
    isLoadingButton: {
      type: Boolean,
      required: true,
      default: () => false,
    },
  },
  setup(props, { emit }) {
    // Form Validation
    const formValid = ref(false)

    const eventLocal = ref({
      title: '',
      type: '',
      event_start_date: '',
      event_end_date: '',
      description: '',
    })
    // ————————————————————————————————————
    //* ——— Template Refs
    // ————————————————————————————————————

    const refCalendarEventHandlerForm = ref(null)

    // ————————————————————————————————————
    //* ——— Local Event
    // ————————————————————————————————————

    // const eventLocal = ref(JSON.parse(JSON.stringify(props.event)))
    const resetEventLocal = () => {
      eventLocal.value = {}

      // Only get date from event
      if (eventLocal.value.event_start_date) {
        eventLocal.value.event_start_date = new Date(eventLocal.value.event_start_date).toISOString().substr(0, 10)
      }
      if (eventLocal.value.event_end_date) {
        eventLocal.value.event_end_date = new Date(eventLocal.value.event_end_date).toISOString().substr(0, 10)
      }

      // Reset Validation
      refCalendarEventHandlerForm.value.resetValidation()
    }
    watch(
      () => props.event,
      () => {
        resetEventLocal()
      },
      { deep: true },
    )

    // ————————————————————————————————————
    //* ——— Form
    // ————————————————————————————————————

    const isStartDateMenuOpen = ref(false)
    const isEndDateMenuOpen = ref(false)

    const guestsOptions = [
      { avatar: require('@/assets/images/avatars/1.png'), name: 'Jane Foster' },
      { avatar: require('@/assets/images/avatars/3.png'), name: 'Donna Frank' },
      { avatar: require('@/assets/images/avatars/5.png'), name: 'Gabrielle Robertson' },
      { avatar: require('@/assets/images/avatars/7.png'), name: 'Lori Spears' },
      { avatar: require('@/assets/images/avatars/6.png'), name: 'Sandy Vega' },
      { avatar: require('@/assets/images/avatars/2.png'), name: 'Cheryl May' },
    ]

    const handleFormSubmit = () => {
      const isFormValid = refCalendarEventHandlerForm.value.validate()

      if (!isFormValid) return

      const eventData = JSON.parse(JSON.stringify(eventLocal.value))

      // * If event has id => Edit Event
      // Emit event for add/update event
      if (eventData.uuid) emit('update-event', eventData)
      else emit('add-event', eventData)

      // Close sidebar
      // emit('close-drawer')
    }

    // ————————————————————————————————————
    //* ——— Perfect Scrollbar
    // ————————————————————————————————————

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
      wheelSpeed: 0.7,
    }

    // ————————————————————————————————————
    //* ——— Form Validator
    // ————————————————————————————————————

    const endDateValidator = val => {
      if (!eventLocal.value.event_start_date) return true

      const secondDate = new Date(eventLocal.value.event_start_date)
      secondDate.setDate(secondDate.getDate() - 1)

      return !dateInPast(new Date(val), secondDate) || 'End date is in past'
    }

    return {
      // Template Refs
      refCalendarEventHandlerForm,

      // Local Event
      eventLocal,
      resetEventLocal,

      // Form
      isStartDateMenuOpen,
      isEndDateMenuOpen,
      guestsOptions,
      handleFormSubmit,

      // Form Validation
      formValid,

      // Perfect Scrollbar
      perfectScrollbarSettings,

      // Field Validators
      validators: {
        required,
        urlValidator,
        endDateValidator,
      },

      // Icons
      icons: {
        mdiTrashCanOutline,
        mdiClose,
      },
    }
  },
}
</script>

<style lang="scss">
.ps-calendar-event-handler {
  height: calc(100% - 44px - 24px - 2px);

  // Fixes: if chips row >2 => Create enough space between chips row & avoid first row chip touching the label
  // Related Issue: https://github.com/vuetifyjs/vuetify/issues/13107
  .select-guest {
    .v-select__selections {
      .v-chip--select {
        margin-bottom: 6px;
        margin-top: 6px;
      }
    }
  }
}
</style>
